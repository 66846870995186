import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path2/src/components/DefaultLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ResponsiveImage = makeShortcode("ResponsiveImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Little Martian is a survival game with a strong emphasis on exploration. Each time you start a new game a unique world is generated procedurally, so no two worlds will ever be the same.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/20-11-12/gps.gif",
        "alt": "GPS Device"
      }}></img></p>
    <p>{`What's more, the rarity of biomes varies between worlds, meaning resources that are scarce in one world could be abundant in another, and vice versa. In turn, this affects which items you'll be able to craft early on in the game, and the hope is that this will cause different strategies to emerge, adding to the replayability of Little Martian.`}</p>
    <p>{`We'll go into detail how the worlds are generated, but first, here's an overview of some of the biomes you're likely to encounter.`}</p>
    <h2>{`Lush Grassland`}</h2>
    <p>{`Found near water – where moisture levels and temperatures are high – lush grasslands provide an abundance of vegetation for Little Martian to eat, and plenty of biofuel to power generators.`}</p>
    <ResponsiveImage src="/images/20-11-12/lush-grassland.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`Temperate Forests`}</h2>
    <p>{`Away from temperature extremes, temperate forests thrive. The types of trees that grow depends on the exact temperatures: where it's a touch warmer, oak trees grow; cooler, and you'll find spruce trees; and sitting in the middle are birch forests. `}</p>
    <ResponsiveImage src="/images/20-11-12/forests.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`Rain Forests`}</h2>
    <p>{`When it's hot and there's a lot of moisture, temperature forests become rain forests, where there's an abundance of thick vines and ferns that are difficult to trek through, but provide an excellent source of biofuel. If you can bring yourself to chop them down?`}</p>
    <ResponsiveImage src="/images/20-11-12/rain-forest.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`Desert`}</h2>
    <p>{`Dry, desolate, and arid; deserts provide very few useful materials, other than the occasional branches that could be fashioned into primitive tools.`}</p>
    <ResponsiveImage src="/images/20-11-12/desert.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`Tundra`}</h2>
    <p>{`Cold, snowy, and icy; the tundra may at first appear to be as sparse as the deserts, but beneath the surface lies a rich, untapped supply of rare minerals and ores. You'll need your spade and plenty of perseverance to find them, however.`}</p>
    <ResponsiveImage src="/images/20-11-12/tundra.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`Sulfur Fields`}</h2>
    <p>{`Also known as a fumarole, sulfur fields occur where an opening in the planet's crust emits sulfur dioxide and other poisonous gases. They are rare, but they are the primary cause of the atmosphere on Unknown Planet being unbreathable. Pools of toxic sulfuric acid make this a dangerous and inhospitable environment, but one that is very rich in rare ores.`}</p>
    <ResponsiveImage src="/images/20-11-12/sulfur-fields.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`Mineral Vein`}</h2>
    <p>{`Another biome that contains an abundance of the extremely rare ores and minerals that you'll need to rebuild your damaged spacecraft.`}</p>
    <ResponsiveImage src="/images/20-11-12/mineral-vein.png" ratio={7 / 16} width={['auto', '1344px']} height={['294px', '588px']} mdxType="ResponsiveImage" />
    <h2>{`World Generation Algorithm`}</h2>
    <p>{`The basic world generation algorithm is based around the standard Perlin noise functions. For a great primer on using noise functions to procedurally generate terrain checkout `}<a parentName="p" {...{
        "href": "https://www.redblobgames.com/maps/terrain-from-noise/"
      }}>{`this amazing article`}</a>{` which does a far better job of explaining it than I ever could! We'll wait...`}</p>
    <p>{`...`}</p>
    <p>{`Thanks for coming back!`}</p>
    <p>{`So, for Little Martian we begin by generating three random noise values for each tile in the world: elevation, temperature, and moisture. These values are combined and we assign biomes in a manner that mimics the sorts of biomes found on Earth. For example, higher moisture levels will result in fertile biomes such as plains and forests, where lots of foliage grows. Lower moisture levels will result in drier biomes such as deserts and barren wastelands.`}</p>
    <p>{`Essentially this is just a bunch of nested if..then..else.. statements.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getBiome`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` m`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` t`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// oceans, unless it's frozen over.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.30`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.31`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BIOMES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`FROZEN_OCEAN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.35`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BIOMES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`OCEAN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BIOMES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`DEEP_OCEAN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// surround oceans with beach, swamp or tundra.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.40`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`t `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.33`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BIOMES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`TUNDRA`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`m `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.58`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BIOMES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`BEACH`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`BIOMES`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`SWAMP`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// and so on...`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This gives us a pleasing mixture of biomes that blend together in a seemingly natural way. It gives rise to nice mix of mostly generic terrain with the occasional unusual landforms to liven the world up. But, to give the player more to explore and discover we layer on top special biomes that are not tied directly to the three variables of elevation, temperature, and moisture.`}</p>
    <p><em parentName="p">{`Mineral veins`}</em>{`, for example, appear as long, curved, slashes across the terrain, cutting through everything above sea-level. `}<em parentName="p">{`Sulfur fields`}</em>{`, however, tend to be small and fairly regular in shape with smooth curved edges.`}</p>
    <h2>{`Generated Structures`}</h2>
    <p>{`In addition to a vast and varied landscape, the planet Little Martian has crash-landed on was once inhabited. The decaying remnants of civilisation are littered all around the planet, just waiting to be discovered.`}</p>
    <p>{`We're keeping most of the details of generated structures a secret, so as not to spoil the surprise! But here's a sneak peek of one structure: randomly generated `}<em parentName="p">{`warehouses`}</em>{`, packed fun of useful materials and tools, often ones that can't be found elsewhere.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/20-11-12/warehouses.gif",
        "alt": "Warehouses"
      }}></img></p>
    <p>{`Other planned structures include: large, abandoned laboratory complexes; derelict living encampments; disused mine entrances (yes, you can go underground, but you're going to want to bring a torch); exploded nuclear reactors; overgrown bio domes; and more...`}</p>
    <p>{`Through your encounters with these structures the history of the planet will be revealed, which may hold clues to its future, and maybe Little Martian's fate!?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      